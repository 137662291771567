import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import Stack from '@mui/material/Stack';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DateFnsUtils from '@date-io/date-fns';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import moment from 'moment';



import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {pathSpliter,dateTimeFormat,checkIntNum,currentDateTime,dateFormat} from '../../lib/functions'


import {currentRouteSet} from '../../actions/actions';


const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getemployees()
    getEmployeeCode()
    getDepartments();
    getDesignations()
    currentRouteSet(pathSpliter(location.pathname,1));
    
  },[])

    // Init states start
 
    
    let [departments,departmentsSet] = useState([])
    let [designations,designationsSet] = useState([])

    let [selectedUnderEmployees,selectedUnderEmployeesSet] = useState([]);
    let [underEmployees,underEmployeesSet] = useState([]);


    let [selectedDesignation,selectedDesignationSet] = useState(null)
    let [selectedDepartment,selectedDepartmentSet] = useState(null)

    let [employees,employeesSet] = useState([])
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)
    
    let [action,action_set] = useState('create');
    let [employee_id,employee_id_set] = useState(0);
    let [employee_code,employee_code_set] = useState('');
    let [employee_name,employee_name_set] = useState('');
    let [ifsc_code,ifsc_code_set] = useState('');
    let [bank_acc_number,bank_acc_number_set] = useState('');
    let [bank_acc_name,bank_acc_name_set] = useState('');
    let [bank_branch,bank_branch_set] = useState('');
    let [zip_code,zip_code_set] = useState('');
    let [present_address,present_address_set] = useState('');
    let [permanent_address,permanent_address_set] = useState('');
    let [contact_no,contact_no_set] = useState('');
    let [email_address,email_address_set] = useState('');
    let [gender_type,gender_type_set] = useState('no');
    let [marital_type,marital_type_set] = useState('no');
    let [basic_salary,basic_salary_set] = useState('');
    let [national_id_no,national_id_no_set] = useState('');
    let [father_name,father_name_set] = useState('');
    let [mother_name,mother_name_set] = useState('');
    let [target_amount,target_amount_set] = useState('');
    let [commission_per,commission_per_set] = useState('');


    let [male,maleSet] = useState(false)
    let [fmale,fmaleSet] = useState(false)
    let [others,othersSet] = useState(false)

    let [single,singleSet] = useState(false)
    let [married,marriedSet] = useState(false)


    

    let [selectedLocation,selectedLocationSet] = useState(null);
    
    
    const [joining_date, joining_date_set] = useState(currentDateTime);
    const [leave_days, leave_days_set] = useState(0);

  

    const employee_name_ref = useRef(null)
    const ifsc_code_ref = useRef(null)
    const bank_acc_number_ref = useRef(null)
    const bank_acc_name_ref = useRef(null)

    const bank_branch_ref = useRef(null)
    const zip_code_ref = useRef(null)
    const present_address_ref = useRef(null)
    const permanent_address_ref = useRef(null)
    const contact_no_ref = useRef(null)
    const email_address_ref = useRef(null)

    const gender_type_ref = useRef(null)
    const marital_type_ref = useRef(null)
    const basic_salary_ref = useRef(null)
    const national_id_no_ref = useRef(null)
    const father_name_ref = useRef(null)
    const mother_name_ref = useRef(null)
    const joining_date_ref = useRef(null)

    const department_ref = useRef(null)
    const designation_ref = useRef(null)
    


    const handleDateChange = (date) => {
        joining_date_set(date);
      };

    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{
          if(employee_name.trim() == ''){
            swal({
              title:'Employee Name is Required.',
              icon:'warning'
            })
          }else if(gender_type == 'no'){
            swal({
              title:'Gender Type is Required.',
              icon:'warning'
            })
          }else{
             loadingSaveSet(true);

            await axios.post(`${API_URL}/api/save-employee`,{
             action,
             employee_id,
             employee_name,
             basic_salary: basic_salary == ''? 0 : basic_salary,
             target_amount: target_amount == ''? 0 : target_amount,
             commission_per: commission_per == ''? 0 : commission_per,
             leave_days: leave_days == ''? 0 : leave_days,
             bank_acc_number,
             bank_acc_name,
             bank_branch,
             ifsc_code,
             zip_code,
             present_address,
             permanent_address,
             contact_no,
             email_address,
             gender_type,
             marital_type,
             national_id_no,
             father_name,
             mother_name,
             department_id : selectedDepartment != null ? selectedDepartment.id : 0,
             designation_id : selectedDesignation != null ? selectedDesignation.id : 0,
             joining_date : joining_date,
             underEmployees : selectedUnderEmployees

            },{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
              getEmployeeCode()
              getemployees()
              selectedUnderEmployeesSet([])
              
              if(res.data.error){
                swal({
                  title: res.data.message,
                  icon:'warning'
                })
              }else{
                swal({
                  title: res.data.message,
                  icon:'success'
                })

                employee_id_set(0)
                leave_days_set(0)
                employee_name_set('')
                action_set('create')
                selectedDepartmentSet(null)
                selectedDesignationSet(null)
                contact_no_set('')
                gender_type_set('no')
                marital_type_set('no')
                joining_date_set(currentDateTime)
                email_address_set('')
                bank_acc_number_set('')
                bank_acc_name_set('')
                bank_branch_set('')
                ifsc_code_set('')

                target_amount_set('')
                commission_per_set('')

                maleSet(false)
                fmaleSet(false)

                singleSet(false)
                marriedSet(false)
                basic_salary_set('')
                zip_code_set('')
                present_address_set('')
                permanent_address_set('')
                national_id_no_set('')
                father_name_set('')
                mother_name_set('')



              }
              
            });
          }
          
    }

    const getEmployeeCode = async ()=>{
      await axios.post(`${API_URL}/api/get-employee-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        employee_code_set(res.data)
      })
    }


    const getemployees = async ()=>{
      await axios.post(`${API_URL}/api/get-employees`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        employeesSet(res.data)
        underEmployeesSet(res.data)
      })
    }

  const getDepartments = async ()=>{
      await axios.post(`${API_URL}/api/get-departments`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        departmentsSet(res.data)
      })
    }

    const getDesignations = async ()=>{
        await axios.post(`${API_URL}/api/get-designations`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
            designationsSet(res.data)
        })
      }





    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>accountEdit(props.rowData)}/>
      
        <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>accountDelete(props.rowData)}/>
            
   </>):''
 }
      
      </div>)
    
    }


    const accountEdit = (row,index)=>{
      let employee =  employees.filter((employee)=>employee.employee_id == row[0])
      employee_id_set(employee[0].employee_id)
      employee_code_set(employee[0].employee_code)
      employee_name_set(employee[0].employee_name)
      action_set('update')


      contact_no_set(employee[0].contact_no)
      leave_days_set(employee[0].leave_days)
      
      gender_type_set(employee[0].gender_type)
      marital_type_set(employee[0].marital_type)
      joining_date_set(employee[0].joining_date)
      selectedDesignationSet({name:employee[0].designation_name,id:employee[0].designation_id})
      selectedDepartmentSet({name:employee[0].department_name,id:employee[0].department_id})
      email_address_set(employee[0].email_address)
      bank_acc_number_set(employee[0].bank_acc_number)
      bank_acc_name_set(employee[0].bank_acc_name)
      bank_branch_set(employee[0].bank_branch)
      zip_code_set(employee[0].zip_code)
      ifsc_code_set(employee[0].ifsc_code)
      present_address_set(employee[0].present_address)
      permanent_address_set(employee[0].permanent_address)
      national_id_no_set(employee[0].national_id_no)
      father_name_set(employee[0].father_name)
      mother_name_set(employee[0].mother_name)
      basic_salary_set(employee[0].basic_salary)

      target_amount_set(employee[0].target_amount)
      commission_per_set(employee[0].commission_per)



      selectedUnderEmployeesSet(employee[0].underEmployees)

   

      if(employee[0].gender_type=='male'){
        maleSet(true)
      }else{
        maleSet(false)
      }

      if(employee[0].gender_type=='fmale'){
        fmaleSet(true)
      }else{
        fmaleSet(false)
      }

      if(employee[0].gender_type=='others'){
        othersSet(true)
      }else{
        othersSet(false)
      }



      
      if(employee[0].marital_type=='single'){
        singleSet(true)
      }else{
        singleSet(false)
      }

      if(employee[0].marital_type=='married'){
        marriedSet(true)
      }else{
        marriedSet(false)
      }

     

   


    }


    const accountDelete = async (row)=>{
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{
        if(yes){
          let employee =  employees.filter((employee)=>employee.employee_id == row[0])
          await axios.post(`${API_URL}/api/delete-employee`,{employee_id:employee[0].employee_id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })

                  getemployees()
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "employee_id",options: { display: 'excluded' }},
      {name: "joining_date",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "employee_code",label: "employee code",options: {filter: true,sort: true}},
      {name: "employee_name",label: "employee name",options: {filter: true,sort: true}},
      {name: "basic_salary",label: "basic salary",options: {filter: true,sort: true}},
      {name: "bank_acc_number",label: "bank acc number",options: {filter: true,sort: true}},
      {name: "bank_acc_name",label: "bank acc name",options: {filter: true,sort: true}},
      {name: "bank_branch",label: "bank branch",options: {filter: true,sort: true}},
      {name: "contact_no",label: "contact no",options: {filter: true,sort: true}},
      {name: "leave_days",label: "Leave Days",options: {filter: true,sort: true}},


      
      {name: "joining_date",label: "joining date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ moment(tableMeta.rowData[10]).format(dateFormat) }</p>) 
        }
      }
      
    },


    {name: "target_amount",label: "target amount ",options: {filter: true,sort: true}},
    {name: "commission_per",label: "commission % ",options: {filter: true,sort: true}},


      
      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }




    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Employee Entry </h2>
      <Grid container spacing={2}>
      
        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          onKeyDown={event => {
            if (event.key === "Enter") {
            }
          }}

        label="Employee Code" disabled name="employee_code" value={employee_code} variant="outlined" size="small" onChange={(e)=>employee_code_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={employee_name_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              basic_salary_ref.current.focus()
            }
          }}
        label="Employee Name" name="employee_name" value={employee_name} variant="outlined" size="small" onChange={(e)=>employee_name_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField type="number"  autoComplete='off' className={classes.fullWidth} 
          inputRef={basic_salary_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              bank_acc_number_ref.current.focus()
            }
          }}
        label="Basic Salary" name="basic_salary" value={basic_salary} variant="outlined" size="small" onChange={(e)=>basic_salary_set(e.target.value)} />
        </Grid>




       
        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={bank_acc_number_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              bank_acc_name_ref.current.focus()
            }
          }}
        label="Bank acc number" name="bank_acc_number" value={bank_acc_number} variant="outlined" size="small" onChange={(e)=>bank_acc_number_set(e.target.value)} />
        </Grid>

        

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={bank_acc_name_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              bank_branch_ref.current.focus()
            }
          }}
        label="Bank acc name" name="bank_acc_name" value={bank_acc_name} variant="outlined" size="small" onChange={(e)=>bank_acc_name_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={bank_branch_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              ifsc_code_ref.current.focus()
            }
          }}
        label="Bank  Branch" name="bank_branch" value={bank_branch} variant="outlined" size="small" onChange={(e)=>bank_branch_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={ifsc_code_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              zip_code_ref.current.focus()
            }
          }}
        label="IFSC Code" name="ifsc_code" value={ifsc_code} variant="outlined" size="small" onChange={(e)=>ifsc_code_set(e.target.value)} />
        </Grid>



        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={zip_code_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              present_address_ref.current.focus()
            }
          }}
        label="Zip code" name="zip_code" value={zip_code} variant="outlined" size="small" onChange={(e)=>zip_code_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={present_address_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              permanent_address_ref.current.focus()
            }
          }}
        label="present address" name="present_address" value={present_address} variant="outlined" size="small" onChange={(e)=>present_address_set(e.target.value)} />
        </Grid>


        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={permanent_address_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              contact_no_ref.current.focus()
            }
          }}
        label="permanent address" name="permanent_address" value={permanent_address} variant="outlined" size="small" onChange={(e)=>permanent_address_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={contact_no_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              email_address_ref.current.focus()
            }
          }}
        label="contact no" name="contact_no" value={contact_no} variant="outlined" size="small" onChange={(e)=>contact_no_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={email_address_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              national_id_no_ref.current.focus()
            }
          }}
        label="email address" name="email_address" value={email_address} variant="outlined" size="small" onChange={(e)=>email_address_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={national_id_no_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              father_name_ref.current.focus()
            }
          }}
        label="National ID No" name="national_id_no" value={national_id_no} variant="outlined" size="small" onChange={(e)=>national_id_no_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={father_name_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              mother_name_ref.current.focus()
            }
          }}
        label="Father Name" name="father_name" value={father_name} variant="outlined" size="small" onChange={(e)=>father_name_set(e.target.value)} />
        </Grid>

       
        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
          inputRef={mother_name_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              joining_date_ref.current.focus()
            }
          }}
        label="Mother Name" name="mother_name" value={mother_name} variant="outlined" size="small" onChange={(e)=>mother_name_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3} style={{marginTop:'-8px'}}> 
        <LocalizationProvider dateAdapter={AdapterDateFns} style={{marginTop: '8px'}}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Joining Date" 
                  inputFormat={dateTimeFormat}
                  value={joining_date}
                  inputRef={joining_date_ref}

                  onChange={(e)=>joining_date_set(e)}
                  renderInput={(params) => <TextField 

                    onKeyDown={event => {
                      if (event.key === "Enter") {
                        department_ref.current.focus()
                      }
                    }}


                    {...params} />}
                />
                
              </Stack>
              </LocalizationProvider>
        </Grid>

      
          

        <Grid item xs={12} sm={3}> 

              <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={departments} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.name}
              value={selectedDepartment}
              onChange={(event,selectedObj)=>{
                selectedDepartmentSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={department_ref}
               

                onKeyDown={event => {
                  if (event.key === "Enter") {
                    designation_ref.current.focus()
                  }
                }}
                  {...params}
                  label="Select Department"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>


        <Grid item xs={12} sm={3}> 

              <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={designations} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.name}
              value={selectedDesignation}
              onChange={(event,selectedObj)=>{
                selectedDesignationSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={designation_ref}
               

                onKeyDown={event => {
                  if (event.key === "Enter") {
                    saveFormAction()
                  }
                }}
                  {...params}
                  label="Select Designation"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>



        <Grid item xs={12} sm={3}> 
        <TextField type="number"  autoComplete='off' className={classes.fullWidth} 
        label="Target Amount" name="target_amount" value={target_amount} variant="outlined" size="small" onChange={(e)=>target_amount_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField type="number"  autoComplete='off' className={classes.fullWidth} 
        label="Commission %" name="commission_per" value={commission_per} variant="outlined" size="small" onChange={(e)=>commission_per_set(e.target.value)} />
        </Grid>


        <Grid item xs={12} sm={3}> 
        <TextField type="number"  autoComplete='off' className={classes.fullWidth} 
        label="Leave Days " name="leave_days" value={leave_days} variant="outlined" size="small" onChange={(e)=>leave_days_set(e.target.value)} />
        </Grid>


   
           
           <Grid item xs={12} sm={3} style={{textAlign: 'left'}}>
            <FormControl component="fieldset" > 
            <FormLabel component="legend" style={{textAlign:'left'}}>Gender Type</FormLabel>
            <RadioGroup row aria-label="gender_type" name="position"   defaultValue={gender_type} onChange={(e)=>{
              
              if(e.target.value=='male'){
                maleSet(true)
              }else{
                maleSet(false)
              }

              if(e.target.value=='fmale'){
                fmaleSet(true)
              }else{
                fmaleSet(false)
              }


              if(e.target.value=='others'){
                othersSet(true)
              }else{
                othersSet(false)
              }




              gender_type_set(e.target.value)
              }} >
              <FormControlLabel value="male" control={<Radio color="primary"  />} checked={male} label="Male " />
              <FormControlLabel value="fmale" control={<Radio color="primary" checked={fmale} />}  label="F-Male " />
              <FormControlLabel value="others" control={<Radio color="primary" checked={others} />}  label="Others " />

            </RadioGroup>
            </FormControl>
            </Grid>



            <Grid item xs={12} sm={3} style={{textAlign: 'left'}}>
            <FormControl component="fieldset" > 
            <FormLabel component="legend" style={{textAlign:'left'}}>Marital Type</FormLabel>
            <RadioGroup row aria-label="marital_type" name="position"   defaultValue={marital_type} onChange={(e)=>{
              if(e.target.value=='single'){
                singleSet(true)
              }else{
                singleSet(false)
              }

              if(e.target.value=='married'){
                marriedSet(true)
              }else{
                marriedSet(false)
              }

              marital_type_set(e.target.value)
              }} >
              <FormControlLabel value="single" control={<Radio color="primary"  />} checked={single} label="Single" />
              <FormControlLabel value="married" control={<Radio color="primary" checked={married} />}  label="Married" />
            </RadioGroup>
            </FormControl>
            </Grid>

    
            <Grid  item xs={12} sm={3} style={{marginBottom:'10px'}}>
      


      <Autocomplete 
          autoHighlight={true}
          openOnFocus={true}
          multiple
          limitTags={10}
          id="multiple-limit-tags" 
  
          style={{ width: '100%' }}
          options={underEmployees} 
          size="small"
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) => option.display_text}
          value={selectedUnderEmployees}
          onChange={(event,selectedObj)=>{
            selectedUnderEmployeesSet(selectedObj)
         
          
          }}
          renderInput={(params) => (
            <TextField
            
              {...params}
              label="Select Under Employees"
              variant="outlined"
            
            />
          )}
          
      />
      </Grid>




       
          




  
  </Grid>


  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Employee List"}
      data={employees}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
      position:'relative'  
},
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);